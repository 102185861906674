<template>
  <div class="paramManage">
    <div class="page-title">预警参数配置</div>
    <div v-if="detailItem">
      <div class="row-me row-center mT50">
        <div class="left-title">行驶心率参数范围：</div>
        <div class="w200">
          <el-input placeholder="请输入" style="width: 100%;" clearable
                    @input="checkInput('travel.heart.rate.min')"
                    v-model="detailItem['travel.heart.rate.min'].configValue"></el-input>
        </div>
        <div class="mLR10">-</div>
        <div class="w200">
          <el-input placeholder="请输入" style="width: 100%;" clearable
                    @input="checkInput('travel.heart.rate.max')"
                    v-model="detailItem['travel.heart.rate.max'].configValue"></el-input>
        </div>
        <div class="mL10">bmp</div>
      </div>
      <div class="row-me row-center mT20">
        <div class="left-title">行驶血压参数范围：</div>
        <div class="w200">
          <el-input placeholder="请输入" style="width: 100%;" clearable
                    @input="checkInput('travel.blood.pressure.min')"
                    v-model="detailItem['travel.blood.pressure.min'].configValue"></el-input>
        </div>
        <div class="mLR10">-</div>
        <div class="w200">
          <el-input placeholder="请输入" style="width: 100%;" clearable
                    @input="checkInput('travel.blood.pressure.max')"
                    v-model="detailItem['travel.blood.pressure.max'].configValue"></el-input>
        </div>
        <div class="mL10">mmhg</div>
      </div>
      <div class="row-me row-center mT20">
        <div class="left-title">行驶血氧参数范围：</div>
        <div class="w200">
          <el-input placeholder="请输入" style="width: 100%;" clearable
                    @input="checkInput('travel.blood.oxygen.min')"
                    v-model="detailItem['travel.blood.oxygen.min'].configValue"></el-input>
        </div>
        <div class="mLR10">-</div>
        <div class="w200">
          <el-input placeholder="请输入" style="width: 100%;" clearable
                    @input="checkInput('travel.blood.oxygen.max')"
                    v-model="detailItem['travel.blood.oxygen.max'].configValue"></el-input>
        </div>
        <div class="mL10">%</div>
      </div>
      <div class="row-me row-center mT20">
        <div class="left-title">常规心率参数范围：</div>
        <div class="w200">
          <el-input placeholder="请输入" style="width: 100%;" clearable
                    @input="checkInput('heart.rate.min')"
                    v-model="detailItem['heart.rate.min'].configValue"></el-input>
        </div>
        <div class="mLR10">-</div>
        <div class="w200">
          <el-input placeholder="请输入" style="width: 100%;" clearable
                    @input="checkInput('heart.rate.max')"
                    v-model="detailItem['heart.rate.max'].configValue"></el-input>
        </div>
        <div class="mL10">bmp</div>
      </div>
      <div class="row-me row-center mT20">
        <div class="left-title">常规血压参数范围：</div>
        <div class="w200">
          <el-input placeholder="请输入" style="width: 100%;" clearable
                    @input="checkInput('blood.pressure.min')"
                    v-model="detailItem['blood.pressure.min'].configValue"></el-input>
        </div>
        <div class="mLR10">-</div>
        <div class="w200">
          <el-input placeholder="请输入" style="width: 100%;" clearable
                    @input="checkInput('blood.pressure.max')"
                    v-model="detailItem['blood.pressure.max'].configValue"></el-input>
        </div>
        <div class="mL10">mmhg</div>
      </div>
      <div class="row-me row-center mT20">
        <div class="left-title">常规血氧参数范围：</div>
        <div class="w200">
          <el-input placeholder="请输入" style="width: 100%;" clearable
                    @input="checkInput('blood.oxygen.min')"
                    v-model="detailItem['blood.oxygen.min'].configValue"></el-input>
        </div>
        <div class="mLR10">-</div>
        <div class="w200">
          <el-input placeholder="请输入" style="width: 100%;" clearable
                    @input="checkInput('blood.oxygen.max')"
                    v-model="detailItem['blood.oxygen.max'].configValue"></el-input>
        </div>
        <div class="mL10">%</div>
      </div>
    </div>
    <div class="row-me row-center mL280 mT100">
      <div class="btn-blue w120 btn-height40" @click="clickSave">保存</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      detailItem: null,
    }
  },
  // 侦听器
  watch: {},
  // 计算属性
  computed: {},
  // html加载完成之前
  created() {
    this.getData()
  },
  // html加载完成后执行。
  mounted() {

  },
  // 事件方法执行
  methods: {
    checkInput(key) {
      this.detailItem[key].configValue = this.$method.checkInput(this.detailItem[key].configValue, 2)
    },
    clickSave() {
      let arr = []
      for (let key in this.detailItem) {
        arr.push({
          configId: this.detailItem[key].configId,
          configValue: this.detailItem[key].configValue || 0,
        })
      }
      this.$post('/system/config/configWatchUpdate', arr).then((res) => {
        if (res) {
          this.$message.success('保存成功')
          this.getData()
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    getData() {
      this.$post('/system/config/configWatch', {}).then((res) => {
        if (res) {
          this.detailItem = res.data
        }
      }).catch((error) => {
        console.log(error)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.paramManage {
  height: 100%;
  margin: 20px;
  padding: 20px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .left-title {
    width: 200px;
    text-align: right;
    margin-right: 10px;
  }
}
</style>
